import React, { useState, useEffect } from "react";
import Navbar from "./NavBar";
import Header from "./Header";
import Footer from "./Footer";
import TopMenu from "./NavBar";
import Swal from "sweetalert2";
import axios from "axios";
import { cloudUrl, secret_key, currentYear } from "../General";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Headsection from "./Headsection";
import cartoon from '../assets/Qatari Cartoon.jpg'
import pica from 'pica';
import yolo from 'tfjs-yolo';

const ManageAppoint = () => {
  let navigate = useNavigate();

  const encryptedToken = localStorage.getItem("token");
  let decryptedToken = "";

  if (encryptedToken) {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
      decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.log("Error.");
    }
  }

  const _token = `Bearer ${decryptedToken}`;

  const storedData = localStorage.getItem("user");

  if (storedData) {
    var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
    var userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setisOpen] = useState(false);
  const [details, setDetails] = useState("");
  const [hotelReservation, setHotelReservation] = useState(null);
  const [travelInsurance, setTravelInsurance] = useState(null);
  const [passportBio, setPassportBio] = useState(null);
  const [passportPic, setPassportPic] = useState(null);
  const [airlineTicket, setAirlineTicket] = useState(null);
  const [hotelName, setHotelName] = useState([]);
  const [newHotelType, setNewHotelType] = useState("");
  const [airlineName, setAirlineName] = useState([]);
  const [otherDocs, setOtherDocs] =useState([]);
  const [bankGuarantee, setBankGuarantee] = useState([]);


    // Function to get the file extension
    function getFileExtension(filename) {
    return `${filename}`.split(".").pop()
  }


  const handleFile1Change = (e) => {
    // setPassportBio(e.target.files[0]);
    const file = e.target.files[0];
    if (file && file.size <= 3 * 1024 * 1024) {
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
          setPassportBio(file);
       }
       else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blur. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('passport_bio').value = '';
            });
            }else{
              setPassportBio(file);
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "File size should be less than or equal to 3MB!",
      }).then(() => {
        document.getElementById('passport_bio').value = '';
      });
    }
  };

  const resizeImage = async (image, targetWidth, targetHeight) => {
    const picaInstance = pica();
    const resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = targetWidth;
    resizedCanvas.height = targetHeight;

    await picaInstance.resize(image, resizedCanvas, {
      unsharpAmount: 80,
      unsharpRadius: 0.6,
      unsharpThreshold: 2,
    });

    return resizedCanvas.toDataURL('image/jpeg');
  };

  const calculateSharpness = async (resizedImageData) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = resizedImageData;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
        const averageColor = imageData.reduce((acc, val) => acc + val, 0) / imageData.length;

        resolve(averageColor);
      };
    });
  };


  const handleFile2Change = (e) => {
    const file = e.target.files[0];
  
    if (file && file.size <= 3 * 1024 * 1024) {
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
          setHotelReservation(file);
       }
       else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blur. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('hotel_reservation').value = '';
            });
            }else{
              setHotelReservation(file);
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "The file uploaded is large. File size should be less than or equal to 3MB!",
      }).then(() => {
        document.getElementById('hotel_reservation').value = '';
      });
    }
  }

  const detectSpectacles = async (imageElement, confidenceThreshold = 0.1) => {
    try {
        // Ensure imageElement is an HTMLImageElement
        if (!(imageElement instanceof HTMLImageElement)) {
            // If imageElement is a string, create an HTMLImageElement and set the src
            if (typeof imageElement === 'string') {
                const imgElement = new Image();
                imgElement.src = imageElement;
                imageElement = imgElement;
            } else {
                throw new Error('Invalid imageElement');
            }
        }

        // Wait for image to load before processing
        await new Promise((resolve) => {
            imageElement.onload = resolve;
        });

        // Convert the HTMLImageElement to ImageBitmap
        const imageBitmap = await createImageBitmap(imageElement);

        // Initialize YOLO model
        let myYolo = await yolo.v3();

        // Make predictions
        const predictions = await myYolo.predict(imageBitmap);

        console.log(predictions)

        // Log confidence scores
        const confidenceScores = predictions.map(prediction => prediction.score);
        console.log('Confidence Scores:', confidenceScores);

        // Filter predictions based on confidence threshold
        const filteredPredictions = predictions.filter(prediction => prediction.score >= confidenceThreshold);
        console.log('Filtered Predictions:', filteredPredictions);

        // Check if there are any predictions after applying the threshold
        const hasSpectacles = filteredPredictions.some(prediction => prediction.class === 'person');
        console.log('Has Spectacles:', hasSpectacles);

        // Release resources
        imageBitmap.close(); // Close the ImageBitmap
        return hasSpectacles;
    } catch (error) {
        console.error('Error in detectSpectacles:', error);
    }
};
  

  const handleFile3Change = (e) => {
    // setTravelInsurance(e.target.files[0]);
    const file = e.target.files[0];
  
    if (file && file.size <= 3 * 1024 * 1024) {
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
          setTravelInsurance(file);
       }
       else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blur. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('travel_insurance').value = '';
            });
            }else{
              setTravelInsurance(file);
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "File size should be less than or equal to 3MB!",
      }).then(() => {
        document.getElementById('travel_insurance').value = '';
      });
    }
  };

  const handleFile4Change = (e) => {
    // setPassportPic(e.target.files[0]);
    const file = e.target.files[0];
  
    if (file && file.size <= 3 * 1024 * 1024) {
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
          Swal.fire({
            icon: 'error',
            title: 'Failed!',
            text: 'The file you uploaded is PDF. Kindly upload png or jpeg',
            }).then(() => {
              // Reset the input value
              document.getElementById('passport_pic').value = '';
          });
        }
        else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blur. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('passport_pic').value = '';
            });
            }else{
              
              // detectSpectacles(img.src).then((res) => {
              //   console.log(res)
              //   if(res == true){
                  setPassportPic(file);
                  // console.log(passportPic);
              //   }else{
              //     Swal.fire({
              //       icon: 'error',
              //       title: 'Failed!',
              //       text: 'The image you uploaded does not contain a face. Kindly upload image with face',
              //     }).then(() => {
              //       // Reset the input value
              //       document.getElementById('passport_pic').value = '';
              //   });
              //   }
              // })
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "File size should be less than or equal to 3MB!",
      }).then(() => {
        document.getElementById('passport_pic').value = '';
      });
    }
  };

  useEffect(() => {
    console.log("Updated passportPic:", passportPic);
}, [passportPic]);
  
  const handleFile5Change = (e) => {
    // setAirlineTicket(e.target.files[0]);
    const file = e.target.files[0];
    if (file && file.size <= 3 * 1024 * 1024) {
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
          setAirlineTicket(file);
       }
       else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blur. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('airline_ticket').value = '';
            });
            }else{
              setAirlineTicket(file);
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "File size should be less than or equal to 3MB!",
      }).then(() => {
        document.getElementById('airline_ticket').value = '';
      });
    }
  };

  const handleFile6Change = (e) => {
    // setAirlineTicket(e.target.files[0]);
    const file = e.target.files[0];
    if (file && file.size <= 3 * 1024 * 1024) {
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
          setBankGuarantee(file);
       }
       else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blur. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('bank_guarantee').value = '';
            });
            }else{
             setBankGuarantee(file);
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "File size should be less than or equal to 3MB!",
      }).then(() => {
        document.getElementById('bank_guarantee').value = '';
      });
    }
  };

  const handleFile7Change = (e) => {
    // setAirlineTicket(e.target.files[0]);
    const file = e.target.files[0];
    if (file && file.size <= 3 * 1024 * 1024) {
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
          setOtherDocs(file);
       }
       else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blur. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('other_docs').value = '';
            });
            }else{
             setOtherDocs(file);
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "File size should be less than or equal to 3MB!",
      }).then(() => {
        document.getElementById('other_docs').value = '';
      });
    }
  };


  const [nationalities, setNationalities] = useState([]);

  const GetNationalitiesCountries = () =>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/Getnationalities`,
      headers: { 
        'Content-Type': 'application/json'
      },

    };
    
    axios.request(config)
    .then((response) => {
      const data = response.data;
      const nationalitiesList = data.map((country) => country.nationality);
      console.log(nationalitiesList)
      setNationalities(nationalitiesList);
      // console.log(response)
      //  setHolidays(response.data.holidays)
    })
    .catch((error) => {
      console.log(error);
    });
  }



  // useEffect(() => {
  //   // Fetch nationalities from an API
  //   fetch('https://restcountries.com/v2/all')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // Extract nationalities from the API response
  //       const nationalitiesList = data.map((country) => country.demonym);
  //       setNationalities(nationalitiesList);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching nationalities:', error);
  //     });
  // }, []);

  const GetHotels =() =>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/Hotelsdropdown`,
      headers: { 
        'Content-Type': 'application/json'
      },
        
    };
  
      axios
        .request(config)
        .then((response) => {
          setHotelName(response.data.hotels)
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    useEffect(() => {
      GetHotels();
      GetNationalitiesCountries();
    }, []);


  const [data, setData] = useState({
    user_id: userData?.id,
    client_no: details?.client_no,
    phone: "",
    given_name: "",
    surname: "",
    email_address: "",
    emergency_contact:"",
    gender:"",
    nationality: "",
    passport_no: "",
    date_of_issue: "",
    date_of_expiry: "",
    date_of_birth: "",
    place_of_birth: "",
    profession: "",
    previous_nationality: "",
    marital_status: "",
    purpose: "",
    visa_validity: "",
    present_postal_address: "",
    present_physical_address: "",
    present_telephone: "",
    destination_postal_address: "",
    destination_physical_address: "",
    destination_telephone: "",
    hotel_name:"",
    flight_number:"",
    hotel_type:newHotelType,
    hotel_reference:"",
    airline_name:"",
    hotel_reservation:"",
    hotel_booking_mode:"",
    check_in_date:"",
    check_out_date:""
  });

  console.log(data.hotel_type)
  console.log(data.hotel_name)

  // set change form values
// const handleChange = (e) =>{
//   const newdata = { ...data }
//   newdata[e.target.id] = e.target.value
//   const selectedHotel = hotelName.find((item) => item.hotel_name === e.target.value);
//   console.log(selectedHotel)
//   //newdata.hotel_type = data.hotel_type = selectedHotel?.hotel_star
//   setData(newdata,
//     data.hotel_type = selectedHotel?.hotel_star,
//     )
//   console.log(data.hotel_type)
//   setNewHotelType(newdata.hotel_type)
//   if (e.target.id === 'airline_name' && newdata.airline_name !== 'Qatar Airways') {
//     Swal.fire({
//       icon: 'error',
//       title: 'Error!',
//       text: 'Are you sure! If not sure, edit your choice.',
//     });
//   }
//   console.log(data)

// }


const handleChange = (e) => {
  const newdata = { ...data };
  newdata[e.target.id] = e.target.value;
  const selectedHotel = hotelName.find((item) => item.hotel_name === e.target.value);

  // Check if selectedHotel is defined before accessing its properties
  if (selectedHotel) {
    newdata.hotel_type = selectedHotel.hotel_star;
  }

  setData(newdata);
  console.log(newdata.hotel_type); // Make sure to log newdata.hotel_type, not data.hotel_type
  setNewHotelType(newdata.hotel_type);

  if (e.target.id === 'airline_name' && newdata.airline_name !== 'Qatar Airways (QR)') {
    Swal.fire({
      text: 'Are you sure! If not sure, edit your choice.',
    });
  }

  if (e.target.id === 'nationality' && newdata.nationality !== 'Kenyan') {
    Swal.fire({
      text: 'Are you sure! If not sure, edit your choice.',
    });
  }

};


  const GetAirlines =() =>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/Airlinesdropdown`,
      headers: { 
        'Content-Type': 'application/json'
      },
        
    };
  
      axios
        .request(config)
        .then((response) => {
          setAirlineName(response.data.airlines)
          console.log(response.data.airlines)
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    useEffect(() => {
      GetAirlines();
    }, []);


const applicant_id = userData?.id

const getCustomerDetails =() =>{
  let config = {
    method: 'get',
    maxBodyLength: Infinity, 
    url: `${cloudUrl}/GetCustomerDetails/${applicant_id}`,
    headers: { 
      'Content-Type': 'application/json'
    },
      data : {
        "applicant_id": applicant_id
      }
  };

    axios
      .request(config)
      .then((response) => {
        setData((prevData) => ({
          ...prevData,
          ...response.data.customer_details,
        }));
        setDetails(response.data.customer_details);
        console.log(response.data)

        if (response.data.response == true) {
          Swal.fire({
            icon: "error",
            title: "Failed!",
            text: "You already have an open appointment. Kindly follow up with the centre!",
          }).then(() => {
            navigate("/history");
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

 
  const applicationform = (e) =>{
    e.preventDefault();
      setLoading(true);
 
      const formData = new FormData();

      // Append files to form data
      if (passportBio) {
        formData.append('passportBio', passportBio);
      }

      if (hotelReservation) {
        formData.append('hotelReservation', hotelReservation);
      }

      if (travelInsurance) {
        formData.append('travelInsurance', travelInsurance);
      }

      if (passportPic) {
        formData.append('passportPic', passportPic);
      }

      if (airlineTicket) {
        formData.append('airlineTicket', airlineTicket);
      }

      if (otherDocs) {
        formData.append('otherDocs', otherDocs);
      }

      if (bankGuarantee) {
        formData.append('bankGuarantee', bankGuarantee);
      }

      // Append JSON data to form data
      formData.append('data', JSON.stringify(data));

      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${cloudUrl }/NewCustomersData`,
        

        headers: { 
          'Content-Type': 'multipart/form-data'
          
        },
        data : formData
      };
       console.log(config.data)
    
        axios
          .request(config)
          .then((response) => {
            setErrorMessage("");
            setLoading(false);
            console.log(response)
            if(response.data.success == 1){
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: response.data.message,
            })
            .then(() => {
              // setShow(true);
              navigate(`/detailscard/${response.data.application_no}`)
            });
            }else{
              if(response.data.success == 0){
                Swal.fire({
                  icon: "error",
                  title: "Failed!",
                  text: response.data.message,
                })
                .then(() => {
                  // setShow(true);
                  navigate(0)
                });
                }
            }
          })
          .catch((error) => {
            setLoading(false);
            setErrorMessage(error?.response?.data?.error);
          });
        }
  
  useEffect(() => {
    getCustomerDetails();
  }, []);

  const [selectedLink, setSelectedLink] = useState("Visa Application form");
  const [isContMenuVisible, setContMenuVisible] = useState(false);
  const [isContMenuVisible1, setContMenuVisible1] = useState(false);
  const [isContMenuVisible2, setContMenuVisible2] = useState(false);
  const [selectedPurpose, setSelectedPurpose] = useState("");

  const getNextDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1); // Adding one day to get the next date
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleRadioChange = (value) => {
    setSelectedPurpose(value);
  };

  const toggleContMenu = () => {
    setContMenuVisible(!isContMenuVisible);
  };

  const toggleContMenu2 = () => {
    setContMenuVisible2(!isContMenuVisible2);
  };
  const toggleContMenu1 = () => {
    setContMenuVisible1(!isContMenuVisible1);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOutsideClick = (e) => {
    if (isMenuOpen && !e.target.closest(".list-column")) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  const handleLinkClick = (link) => {
    setSelectedLink(link);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  const [showFullText, setShowFullText] = useState(false);

  const visaApplicationText = 'I hereby confirm that the information provided in this visa application is true, complete and accurate, to the best of my knowledge and belief and that the documents submitted are genuine. I understand that in the event of my information being found false or incorrect at any stage, my visa shall be denied without notice of compensation in lieu thereof. In addition, I consent that all applications are subject to approval by the Embassy of the State of Qatar. Submission of a visa application does not necessarily mean that a visa will be granted and the visa processing fee cannot be refunded in any circumstance.';

  const truncatedText = showFullText
    ? visaApplicationText
    : `${visaApplicationText.slice(0, 80)}...`;
  
  return (
    <>
      <Headsection/>
      <div className="containerH">
        <section>
          <div className="columns">
            {/* <div className="column list-column">
              <div>
                <ul className="list">
                  <li onClick={() => handleLinkClick("Visa centre")}>
                    <a href="#">visa centre</a>
                  </li>
                  <li onClick={() => handleLinkClick("About Us")}>
                    <a href="#">About Us</a>
                  </li>
                  <li >
                    <a href="/faqs">FAQs </a>
                  </li>
                  <li >
                    <a href="/contact">Contact Us </a>
                  </li>

                  <li >
                    <a href="/manage_appointment">Track Applications </a>
                  </li>
                  <li >
                    <a href="/consular_services">Services </a>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="column content-column">
              <div>
                <h2>{selectedLink} 
                <img src={cartoon} alt="Watermark" className="imagesize" />
                </h2>
                
                <p>
                  
                  {/* Content for selected link */}
                  {selectedLink === "Visa Application form" && (
                    <>
                      <div>
                        <div className="servicebtn">
                          <button onClick={() => setisOpen(!isOpen)}>
                            <i className="ri-arrow-drop-right-line"></i>
                          </button>
                        </div>
                        {isOpen && (
                          <div className="service_menu">
                            <a href="#" onClick={() => handleLinkClick("About Us")}>About us</a>
                            <a href="/faqs">FAQS</a>
                            <a href="/contact">Contact us</a>
                            <a href="/manage_appointment">Track application</a>
                            <a href="/consular_services">Services</a>
                          </div>
                        )}
                      </div>
                      <div></div>

                      <div>
                        <form
                          onSubmit={applicationform}
                          className="applicationf  shadow p-3 mb-5 bg-white rounded "
                        >
                          {/* <h2 className="d-flex text-center justify-center text-pink">
                            {" "}
                            Entry Visa Application Form
                          </h2> */}
                          {/* <img src={cartoon} alt="Watermark" className="watermark" /> */}

                          <div className="form-containerWE">
                            
                            <div className="form-groupWE">
                              <label>Surname</label>
                              <br />
                              <input
                                className="form-control"
                                id="surname"
                                value={data?.surname}
                                onChange={handleChange}
                                type="text"
                                required
                              />
                            </div>

                            {/* Question 2 */}
                            <div className="form-groupWE">
                              <label htmlFor="question2">Given Name (s)</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="given_name"
                                value={data?.given_name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Email</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="email_address"
                                value={data?.email_address}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Emergency Contact No</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="emergency_contact"
                                value={data?.emergency_contact}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Gender</label>
                              <br />
                              <select
                              className="form-control"
                              type="text"
                              id="gender"
                              value={data?.gender}
                              onChange={handleChange}
                              required
                              >
                              <option selected>Select ...</option>
                              <option value="Female">Female</option>
                              <option value="Male">Male</option>
                              </select>
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Nationality</label>
                              <br />
                              <select
                                className="form-control form-select"
                                type="text"
                                id="nationality"
                                value={data?.nationality}
                                onChange={handleChange}
                                required
                                style={{ flexDirection: 'column' }}
                              >
                                <option value="Kenyan">Kenyan</option>

                                {nationalities.map((nationality,index) => (
                                  <option key={index} value={nationality}>
                                    {nationality}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Passpport No</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="passport_no"
                                value={data?.passport_no}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Date of Issue</label>
                              <br />
                              <input
                                className="form-control"
                                type="date"
                                id="date_of_issue"
                                value={data?.date_of_issue}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Date of Expiry</label>
                              <br />
                              <input
                                className="form-control"
                                type="date"
                                id="date_of_expiry"
                                value={data?.date_of_expiry}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Place of Birth</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="place_of_birth"
                                value={data?.place_of_birth}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Date of Birth</label>
                              <br />
                              <input
                                className="form-control"
                                type="date"
                                id="date_of_birth"
                                value={data?.date_of_birth}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Profession</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="profession"
                                value={data?.profession}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">
                                Previous Nationality
                              </label>
                              <br />
                              <select
                                className="form-control form-select"
                                type="text"
                                id="previous_nationality"
                                value={data?.previous_nationality}
                                onChange={handleChange}
                                style={{flexDirection:'column'}}
                              >

                              <option value="">Select previous nationality</option>
                              {nationalities.map((nationality,index) => (
                                <option key={index} value={nationality}>
                                  {nationality}
                                </option>
                              ))}
                                </select>
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Marital Status</label>
                              <br />
                              <select
                                className="form-control form-select"
                                type="text"
                                id="marital_status"
                                value={data?.marital_status}
                                onChange={handleChange}
                              >
                                <option selected>Select ...</option>
                                <option value="Married">Married</option>
                                <option value="Single">Single</option>
                                <option value="Divorced">Divorced</option>
                              </select>
                            </div>
                            <div>
                              <label htmlFor="question2">Purpose of Visa</label>
                              <br />
                              <select
                                className=" form-control form-select"
                                id="purpose"
                                value={data?.purpose}
                                onChange={handleChange}
                              >
                                <option selected>Select ...</option>
                                <option value="Business">Business</option>
                                <option value="Tourist">Tourist</option>
                              </select>
                            </div>

                            <div>
                              <label htmlFor="question2">Visa Validity</label>
                              <br />
                              <select
                                className=" form-control form-select"
                                id="visa_validity"
                                value={data?.visa_validity}
                                onChange={handleChange}
                              >
                                <option selected>Select ...</option>
                                <option value="1 month">One month</option>
                                <option value="3 months">Three months</option>
                              </select>
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Present Postal Address</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="present_postal_address"
                                value={data?.present_postal_address}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Present Physical Address</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="present_physical_address"
                                //placeholder="Enter street name of the Address"
                                value={data?.present_physical_address}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">
                                Present Telephone
                              </label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="present_telephone"
                                //placeholder="Enter present phone number "
                                value={data?.present_telephone}
                                onChange={handleChange}
                                required
                              />
                            </div>

                            <div className="form-groupWE">
                              <label htmlFor="question2">
                              Destination Postal Address
                              </label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="destination_postal_address"
                                //placeholder="Enter destination address and area"
                                value={data?.destination_postal_address}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">
                              Destination Physical Address
                              </label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="destination_physical_address"
                               //placeholder="Enter street name of the address"
                                value={data?.destination_physical_address}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">
                                Destination Telephone
                              </label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="destination_telephone"
                                value={data?.destination_telephone}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                            <label htmlFor="question2">Hotel Name</label>
                            <br />
                            <select
                              className=" form-control select"
                              id="hotel_name"
                              onChange={handleChange}
                              value={data?.hotel_name}
                              >
                              <option value="">Select Hotel ...</option>
                              {hotelName.map((item) => (
                                <option key={item.id} value={item.hotel_name}>
                                  {item.hotel_name}
                                </option>
                              ))}
                            </select>
                          </div>
                            <div className="form-groupWE">
                          <label htmlFor="question2">
                          Hotel Type
                          </label>
                          <br />
                          <input
                            className="form-control"
                            type="text"
                            value={newHotelType}
                            onChange={handleChange}
                            readOnly
                          />
                        </div>
                        <div className="form-groupWE">
                          <label htmlFor="question2">Hotel Booking Portal</label>
                          <br />
                          <select
                          className="form-control"
                          type="text"
                          id="hotel_booking_mode"
                          value={data?.hotel_booking_mode}
                          onChange={handleChange}
                          required
                          >
                          <option selected>Select ...</option>
                          <option value="Discover Qatar">Discover Qatar</option>
                          <option value="TBO Holidays">TBO Holidays</option>
                          <option value="SOTC Travel">SOTC Travel</option>
                          </select>
                        </div>
                        <div className="form-groupWE">
                          <label htmlFor="question2">
                          Hotel Confirmation Reference
                          </label>
                          <br />
                          <input
                            className="form-control"
                            type="text"
                            id="hotel_reference"
                            value={data?.hotel_reference}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-groupWE">
                          <label htmlFor="question2">Check in Date</label>
                          <br />
                          <input
                            className="form-control"
                            type="date"
                            id="check_in_date"
                            value={data?.check_in_date}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-groupWE">
                          <label htmlFor="question2">Check out Date</label>
                          <br />
                          <input
                            className="form-control"
                            type="date"
                            id="check_out_date"
                            value={data?.check_out_date}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="form-groupWE">
                          <label htmlFor="question2">
                          Airline Name
                          </label>
                          <br />
                          <select
                            className=" form-control select"
                            id="airline_name"
                            onChange={handleChange}
                            value={data?.airline_name}
                            required
                            >
                            <option value="">Select airline ...</option>
                            {airlineName.map((item) => (
                              <option key={item.id} value={item}>
                                {item}
                              </option>
                            ))}
                            </select>
                        </div>
                        <div className="form-groupWE">
                          <label htmlFor="question2">
                          Flight Booking Reference
                          </label>
                          <br />
                          <input
                            className="form-control"
                            type="text"
                            id="flight_number"
                            value={data?.flight_number}
                            onChange={handleChange}
                            required
                          />
                        </div>
                          <div className="form-groupWE">
                            <label htmlFor="question2">
                            Passport Biodata
                            </label>
                            <br />
                            <input
                            className="form-control"
                            type="file"
                            id="passport_bio"
                            placeholder="Attach your passport biodata image"
                            //value={data?.passport_bio}
                            onChange={handleFile1Change}
                            required
                            />
                          </div>
                          <div className="form-groupWE">
                            <label htmlFor="question2">
                            Passport size photo 
                            </label>
                            <br />
                            <input
                            className="form-control"
                            type="file"
                            id="passport_pic"
                            placeholder="Attach your passport 4.8 by 3.8"
                            //value={data?.passport_pic}
                            onChange={handleFile4Change}
                            required
                            />
                          </div>
                          <div className="form-groupWE">
                            <label htmlFor="question2">
                            Airline Ticket 
                            </label>
                            <br />
                            <input
                            className="form-control"
                            type="file"
                            id="airline_ticket"
                            //value={data?.airline_ticket}
                            onChange={handleFile5Change}
                            required
                            />
                          </div>
                          <div className="form-groupWE">
                            <label htmlFor="question2">
                            Hotel reservations confirmation 
                            </label>
                            <br />
                            <input
                            className="form-control"
                            type="file"
                            id="hotel_reservation"
                            placeholder="Attach your passport 4.8 by 3.8"
                            //value={data?.hotel_reservation}
                            onChange={handleFile2Change}
                            required
                            />
                          </div>
                          <div className="form-groupWE">
                            <label htmlFor="question2">
                            Travel Insurance
                            </label>
                            <br />
                            <input
                            className="form-control"
                            type="file"
                            id="travel_insurance"
                            name="travel_insurance"
                            //value={data?.travel_insurance}
                            onChange={handleFile3Change}
                            required
                            />
                          </div>
                          <div className="form-groupWE">
                            <label htmlFor="question2">
                            Bank Guarantee (optional)
                            </label>
                            <br />
                            <input
                            className="form-control"
                            type="file"
                            id="bank_guarantee"
                            name="bank_guarantee"
                            onChange={handleFile6Change}
                            />
                        </div>
                        <div className="form-groupWE">
                            <label htmlFor="question2">
                            Other Documents (optional)
                            </label>
                            <br />
                            <input
                            className="form-control"
                            type="file"
                            id="other_docs"
                            name="other_docs"
                            onChange={handleFile7Change}
                            />
                        </div>
                          </div>
                          <div>
                          <label>
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                          <div>
                          <p>{truncatedText} <button onClick={() => setShowFullText(!showFullText)}>{showFullText ? ' View less' : 'View more'}</button></p>
                          </div>
                          
                        </label>
                        </div>

                          <button
                            type="submit"
                            className="submitbtn"
                            // value={loading ? "Submitting..." : "Submit"}
                            disabled={!isChecked || loading}
                          >
                            {loading ? "Saving..." : "Save"}
                          </button>
                        </form>
                      </div>
                    </>
                  )}
                  {/* Add similar blocks for other links */}
                  {selectedLink === "About Us" && (
                    <>
                      Project
                      <br />
                      The Ministry of Interior, State of Qatar has awarded a
                      mandate to Biomet Services Pte. Ltd to provide select
                      residency procedures on its behalf in eight countries. As
                      per the agreement, those coming to work in Qatar must
                      complete their biometric enrollment, undergo medical
                      examinations and sign their work contracts in their home
                      countries before coming to Qatar. The main objective
                      behind this project is for The Ministry of Interior to
                      align the rights of the workers with the best
                      international standards while providing the services in
                      the state of the art facilities through a seamless
                      efficient process. As part of the biometric enrollment,
                      applicants will provide facial image, fingerprints and
                      iris scan while the medical tests would include vision
                      check-up, certain blood test, X-Ray and the administration
                      of Diphtheria and Tetanus vaccinations.
                      <br />
                      <br />
                      Management
                      <br />
                      Oryx Visa centres are managed by a team of professionals
                      who are dedicated to the idea of building an organization
                      on the principals of trust, transparency and constant
                      innovation. Together, a culture is being built that is
                      able to serve the visa applicants with highest commitments
                      to quality, comfort and care.
                    </>
                  )}


                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default ManageAppoint;
